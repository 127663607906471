import React from "react";

import "./src/styles/reset.scss";
import "./src/styles/main.scss";
import "@fontsource/bebas-neue/latin-400.css";
import "@fontsource-variable/nunito/wght.css";

import CookieBanner from "./src/components/cookie-banner/cookie-banner";


export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <>
    {element}
    <CookieBanner />
  </>
);