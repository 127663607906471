import React, { useState, useEffect } from "react";
import "./cookie-banner.scss";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true, // Always enabled
    analytics: true,
  });

  useEffect(() => {
    const consent = localStorage.getItem("cookie-consent");
    if (!consent) setIsVisible(true);
  }, []);

  const handleSavePreferences = () => {
    localStorage.setItem(
      "cookie-consent",
      JSON.stringify(preferences)
    );
    setIsVisible(false);
  };

  const acceptAllCookies = () => {
    setPreferences({ necessary: true, analytics: true });
    localStorage.setItem(
      "cookie-consent",
      JSON.stringify({ necessary: true, analytics: true })
    );
    setIsVisible(false);
  };

  const handlePreferenceChange = (key: string) => {
    setPreferences((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    isVisible && (
      <div
        className="cookie-banner"
        role="dialog"
        aria-live="polite"
        aria-label="Cookie consent banner"
      >
        {!showPreferences ? (
          <>
            <p>
              We use cookies to enhance your browsing experience and analyse our traffic. By clicking "Accept all", you consent to our use of cookies. Learn more in our{" "}
              <a href="/privacy-and-gdpr">Privacy Policy</a>.
            </p>
            <div className="cookie-buttons">
              <button className="btn-outline-primary accept-all" onClick={acceptAllCookies} tabIndex={0}>
                Accept All
              </button>
              <button className="btn-outline-primary" onClick={() => setShowPreferences(true)} tabIndex={0}>
                Preferences
              </button>
              <button className="btn-outline-primary" onClick={() => setIsVisible(false)} tabIndex={0}>
                Decline
              </button>
            </div>
          </>
        ) : (
          <div className="cookie-preferences-container">
            <div className="content">
              <p>Select your cookie preferences:</p>
              <div className="cookie-preferences">
                <label>
                  <input
                    type="checkbox"
                    checked
                    disabled
                  />
                  Necessary Cookies (Always Enabled)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.analytics}
                    onChange={() => handlePreferenceChange("analytics")}
                  />
                  Analytics Cookies
                </label>
              </div>
            </div>
            <div className="cookie-buttons">
              <button className="btn-outline-primary" onClick={handleSavePreferences} tabIndex={0}>
                Save Preferences
              </button>
              <button className="btn-outline-primary" onClick={() => setShowPreferences(false)} tabIndex={0}>
                Back
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default CookieBanner;
