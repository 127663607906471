exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-bio-tsx": () => import("./../../../src/pages/about-bio.tsx" /* webpackChunkName: "component---src-pages-about-bio-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-disclaimer-tsx": () => import("./../../../src/pages/legal-disclaimer.tsx" /* webpackChunkName: "component---src-pages-legal-disclaimer-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-privacy-and-gdpr-tsx": () => import("./../../../src/pages/privacy-and-gdpr.tsx" /* webpackChunkName: "component---src-pages-privacy-and-gdpr-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-personal-template-tsx": () => import("./../../../src/templates/personal-template.tsx" /* webpackChunkName: "component---src-templates-personal-template-tsx" */),
  "component---src-templates-work-template-tsx": () => import("./../../../src/templates/work-template.tsx" /* webpackChunkName: "component---src-templates-work-template-tsx" */)
}

